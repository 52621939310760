@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.App {
  text-align: center;
  background: #fff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



* { font-family: 'Roboto', sans-serif; }
body {
  background-size: cover;
}

.content-wrapper { width: 60%; margin: auto; position: relative; height: 100vh; }
.full-lenght { width: 100%; }
.vertical-align {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}



/* ################################# LOGIN STYLING ################################# */

#loginForm {
  width: 30%;
  padding: 20px 80px;
  background: #00000082;
  border-radius: 25px;
  color: #fff;
  margin: auto;
}

#login-header { font-weight: 200; margin-top: 30px; margin-bottom: 20px; }

.login-rows { margin-bottom: 20px; }
.login-rows:last-child { margin-bottom: 0px !important; }
.login-rows label { width: 100%; text-align: center; font-weight: 100; font-size: 25px; margin-bottom: 10px; }
.login-rows input {
  padding: 15px 25px;
  border-radius: 20px;
}

/* ################################# LOGIN STYLING ################################# */




/* ################################# MAIN PAGE ################################# */

#displayTime { font-size: 135px; font-weight: 100; }

#service-title {
  font-size: 55px;
  font-weight: 300;
}

#displayDate {
  font-size: 30px;
    font-weight: 100;
  }

#profile-wrapper {
  margin-left: 5%;
  width:80%;
  padding: 35px 25px;
  background: #00000082;
  border-radius: 25px;
  color: #fff;
}

#profile-image {
  width: 200px;
  background: #fff;
  height: 250px;
  border-radius: 25px;
  background-size: cover;
  background-position: center;
}

#profile-name { margin-top: 30px; }
#given-name { font-size: 30px; }
#church-designation { font-size: 20px; font-weight: 100; }

#status-remark {
  width: 90%;
  background: #fff;
  height: 60px;
  margin-top: 80px;
  border-radius: 25px;
  border: none;
  text-align: center;
}

#status-remark:focus {
  border: none !important;
  outline: none;
}

/* ################################# MAIN PAGE ################################# */